import "./TopperStyles.css";
import TopperData from "./TopperData";
import Topper1 from "../assets/t1.jpeg";
import Topper2 from "../assets/t2.jpeg";
import Topper3 from "../assets/t3.jpeg";
import topper4 from "../assets/t4.jpg"
import topper5 from "../assets/t5.jpg"
import topper6 from "../assets/t6.jpg"
import Topper7 from "../assets/t7.jpg";
import topper8 from "../assets/t8.jpg"
import topper9 from "../assets/t9.jpg"
import topper10 from "../assets/t10.jpg"

function Topper() {
  return (
    <div className="Topper">
      <h1>Our Toppers</h1>
      <p>
        Student's " Top Rank-Top Score-Top Result " with Help of Top Rank Makers{" "}
      </p>
      <div className="Toppercard">
      <TopperData image={topper9} heading=" Gavane Paras Ramnath" />

        <TopperData image={Topper3} heading=" Pranva Kumar Kurle" />

        <TopperData image={Topper7} heading=" Julaikha Akbar Sagre" />

       
      </div>
      <br /> {/* Add the <br> tag here */}
      <div className="Toppercard">
      <TopperData image={Topper2} heading=" Vedanti Sanjay Bhusari" />

<TopperData image={Topper1} heading=" Shravani Padmakar Phutane" />

        <TopperData image={topper4} heading=" Vibha Girdhar Thakare" />

       
      </div>
      <br /> {/* Add the <br> tag here */}
      <div className="Toppercard">
      <TopperData image={topper5} heading=" Riddhika Vijay Gaupal" />

<TopperData image={topper6} heading=" Komal Manohar Bahe" />
       

        <TopperData image={topper8} heading=" Prajakt Bapu Zanje" />


      </div>
      ' sa<br /> {/* Add the <br> tag here */}
      <div className="Toppercard">
      <TopperData image={topper10} heading=" Samriddhi Praveen Sonawanev " />

          
      </div>
    </div>
  );
}

export default Topper;
