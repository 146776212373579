import React from "react";
import "./PopupImage.css";
import imageSrc from "../assets/popup.jpg"

const PopupImage = ({ isOpen, onClose, imageSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          ✖
        </button>
        <img src={imageSrc} alt="Popup" />
      </div>
    </div>
  );
};

export default PopupImage;
