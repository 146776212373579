import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import GalleryPhoto from "../component/ElectionUs";
import logo from "../assets/ele1.jpg";
import ElectionUs from "../component/ElectionUs";

function Election() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImage="https://www.colorhexa.com/a3247a.png"
        title="1st In India, AI-IN Education"
        image={logo}
      />
      <ElectionUs />
      <Footer />
    </>
  );
}
export default Election;
